<template>
    <!--
    Страница списка пользователей
    -->
    <el-container direction="vertical">

        <dom-table
            :items="users"
            :rows-per-page="10"
            :search-fn="filter_data"
            :default-sort="{prop: 'name', order: 'ascending'}">

            <template v-if="isMobile">
                <el-table-column width="40px" label="#" align="center" type="index"/>

                <el-table-column sortable>
                    <template v-slot:header="scope">
                        <i class="fas el-icon-fa-fw el-icon-fa-users mr-10"/>
                        Пользователь
                    </template>
                    <template v-slot="scope">
                        <i v-if="scope.row.jurForm === 'org'" class="fas el-icon-fa-fw el-icon-fa-user-tie mr-10 item-user-icon"/>
                        <i v-else class="fas el-icon-fa-fw el-icon-fa-user mr-10 item-user-icon"/>
                        <span class="vote-title">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="Адрес" sortable>
                    <template v-slot="scope">
                        <span class="vote-title">{{ scope.row.address }}</span>
                    </template>
                </el-table-column>

                <el-table-column sortable label="Email" width="250px">
                    <template v-slot="scope">
                        <span class="vote-title">{{ scope.row.email }}</span>
                    </template>
                </el-table-column>

                <el-table-column width="40px">
                    <template v-slot:header="scope">
                        <i class="fas el-icon-fa-fw el-icon-fa-eye"/>
                    </template>
                    <template v-slot="scope">
                        <router-link :to="{name: 'user', params: {id: scope.row.userId}}">
                            <i class="fas el-icon-fa-fw el-icon-fa-eye users-view-item"/>
                        </router-link>
                    </template>
                </el-table-column>

                <el-table-column width="40px">
                    <template v-slot:header="scope">
                        <i class="fas el-icon-fa-fw el-icon-fa-times-circle"/>
                    </template>
                    <template v-slot="scope">
                        <i class="fas el-icon-fa-fw el-icon-fa-times-circle users-remove-item" @click="removeUser(scope.row)"/>
                    </template>
                </el-table-column>
            </template>
            <!--            карточки для мобильной версии-->
            <template v-slot:mobile="{computedTableData}" v-if="!isMobile">
                <el-card class="box-card" v-for="(user, idx) in computedTableData" :key="idx">
                    <div slot="header" class="card-header">
                        <div>
                            <span>#{{ idx + 1 }}</span>
                            <i v-if="user.jurForm === 'org'" class="fas el-icon-fa-fw el-icon-fa-user-tie ml-5 mr-5 item-user-icon"/>
                            <i v-else class="fas el-icon-fa-fw el-icon-fa-user ml-5 mr-5 item-user-icon"/>
                            <span>{{ user.name }}</span>
                        </div>
                        <div>
                            <router-link :to="{name: 'user', params: {id: user.userId}}" style="margin-right: 5px;">
                                <i class="fas el-icon-fa-fw el-icon-fa-eye vote-view-link"/>
                            </router-link>
                            <router-link :to="{}">
                                <i class="fas el-icon-fa-fw el-icon-fa-times-circle users-remove-item" @click="removeUser(user)"/>
                            </router-link>
                        </div>
                    </div>
                    <div>
                        <span style="font-weight: bold;">Адрес:</span>
                        <span> {{ user.address }}</span>
                    </div>
                    <div style="margin-top: 10px">
                        <span style="font-weight: bold">E-mail:</span>
                        <span> {{ user.email }}</span>
                    </div>
                </el-card>
            </template>

        </dom-table>

        <code-confirmation-dialog v-model="removeUserDialog" :request-code="onGetCode" :apply-code="onRemoveUser"/>

    </el-container>

</template>

<script>

import {mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";
import DomTable from "@/views/elements/DomTable";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import api from "@/api";
import VueScreenSize from "vue-screen-size";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],
    components: {CodeConfirmationDialog, DomTable},

    data: () => ({
        perPage: 10,
        removeUserDialog: false,
        userId: null,
    }),

    mounted() {
        this.getAll()
    },

    computed: {
        ...mapFields("users", ['currentPage', 'users', 'search']),
        isMobile() {
            return this.$vssWidth > 1000
        },
    },

    methods: {
        ...mapActions("users", ["getAll"]),

        filter_data(row) {
            const query = (this.search || "").toLowerCase()
            const check = field => ("" + field).toLowerCase().includes(query)
            const check_sub = (arr, field) => _.filter(arr, d => check(d[field])).length

            return check(row.name) || check(row.address) || check(row.email) || check(row.inn) ||
                check_sub(row.usersProp, 'address') || check_sub(row.usersProp, 'cadNumber')
        },

        removeUser({userId}) {
            this.userId = userId;
            this.removeUserDialog = true;
        },

        onGetCode() {
            return api.users.getCode(this.userId)
        },

        onRemoveUser(code) {
            return api.users.remove(code, this.userId)
                .then(() => this.getAll())
        },

    },
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.box-card {
    margin-top: 10px;

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
    }

    .card-row {
        margin-top: 10px;
    }
}

.vote-view-link {
    color: $dom-light-blue;
}

.item-user-icon {
    color: $dom-light-blue;
}

.users-view-item {
    color: $dom-light-blue;
    margin-top: 6px;
}

.users-remove-item {
    color: $dom-red;
    margin-top: 6px;
    cursor: pointer;
}

.dom-table ::v-deep .el-icon-arrow-right {
    position: initial;
    margin: 0;
    color: $dom-light-blue;
}

//noinspection CssNoGenericFontName
.dom-table ::v-deep .el-table__expand-icon i::before {
    content: "\f06e";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    font-weight: bold;
}

//noinspection CssNoGenericFontName
.dom-table ::v-deep .el-table__expand-icon--expanded i::before {
    content: "\f070";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    font-weight: bold;
}

.dom-table ::v-deep .el-table__expand-icon--expanded {
    transform: none;
}


</style>

